<template>
  <div>
    <NavigationLayout v-if="show"></NavigationLayout>
    <AdminNavLayout v-if="!show"></AdminNavLayout>

    <v-content>
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
import NavigationLayout from "./components/Layout/NavigationUser.vue"
import AdminNavLayout from "./components/Layout/NavigationAdmin.vue"
export default {
  name: "App",
  components: {
    NavigationLayout,
    AdminNavLayout
  },
  data() {
    return {
      show: true
    };
  },
  watch: {
    $route(to) {
      // Thay đổi layout dựa trên đường dẫn
      if (to.path.startsWith("/admin")) {
        this.show = false;
      } else {
        this.show = true;
      }
    }
  },

};
</script>

<style>
/* Styles của bạn */
</style>
